<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 icon-vis2 gray-bg receptions-screen reception-email-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_edit-reception' }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div
          class="component-title"
          v-if="templateTypeId && templateTypeId == 1"
        >
          {{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "email-invited"
            )
          }}
        </div>
        <div
          class="component-title"
          v-if="templateTypeId && templateTypeId == 2"
        >
          {{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "email-updated"
            )
          }}
        </div>
        <div
          class="component-title"
          v-if="templateTypeId && templateTypeId == 3"
        >
          {{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "email-deleted"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <!-- is active -->
    <ul class="clebex-item-section" v-if="visitorTemplate">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="isActive"
                  type="checkbox"
                  :value="parseCheckboxValue(visitorTemplate.is_active)"
                  :checked="parseCheckboxValue(visitorTemplate.is_active)"
                  @change="handleIsActiveChange"
                />
                <label for="isActive"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "active"
                )
              }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <!-- subject -->
    <ul class="clebex-item-section" v-if="visitorTemplate">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="subject">
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "subject"
                )
              }}
            </label>
            <input
              type="text"
              :value="visitorTemplate.subject"
              @change="event => handleInputChange(event, 'subject')"
              id="subject"
            />
          </div>
        </div>
      </li>
    </ul>
    <!-- body -->
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item mail-flex">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="body">
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "body"
                )
              }}
            </label>

            <label
              class="clebex-section-input-label reception-mail-variables"
              for="body"
            >
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "variables"
                )
              }}
            </label>

            <label
              class="clebex-section-input-label reception-mail-variables variable"
              for="body"
              >{{ placeholders.join(", ") }}</label
            >
          </div>
        </div>
      </li>
    </ul>
    <ul
      class="clebex-item-section mail-flex"
      v-if="visitorTemplate && !isPreview"
    >
      <li class="clebex-item-section-item mail-flex">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <textarea
              type="text"
              :value="visitorTemplate.body"
              @change="event => handleInputChange(event, 'body')"
              id="body"
            />
          </div>
        </div>
      </li>
    </ul>

    <ul
      class="clebex-item-section mail-flex"
      v-if="visitorTemplate && isPreview"
    >
      <li class="clebex-item-section-item mail-flex">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input preview">
            <div v-html="visitorTemplate.body"></div>
          </div>
        </div>
      </li>
    </ul>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: isPreview }"
              @click="setPreview"
            >
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "preview"
                )
              }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { placeholders } from "@/services/consts/receptions";

export default {
  name: "EditReceptionEmail",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      isPreview: false,
      selectedTemplateIndex: null,
      placeholders: placeholders
    };
  },
  created() {
    if (typeof this.templateTypeId === undefined || !this.reception) {
      this.$router.push({
        name: "r_edit-reception",
        params: this.$router.params
      });
    }
  },
  computed: {
    ...mapState("reception", ["visitorTemplate", "reception"]),
    templateTypeId() {
      const { typeId } = this.$route.params;
      return typeId;
    }
  },
  methods: {
    ...mapActions("reception", ["setVisitorTemplate", "setReception"]),
    parseCheckboxValue(val) {
      if (typeof val === "string") return Boolean(Number(val));
      return Boolean(val);
    },
    handleInputChange(event, field) {
      const value = event.target.value;
      const reception = { ...this.reception };
      reception.visitor_template[this.selectedTemplateIndex][field] = value;
      this.setReception(reception);
    },
    handleIsActiveChange(event) {
      const checked = event.target.checked;
      const reception = { ...this.reception };
      reception.visitor_template[this.selectedTemplateIndex].is_active = checked
        ? 1
        : 0;
      this.setReception(reception);
    },
    setPreview() {
      this.isPreview = !this.isPreview;
    }
  },
  watch: {
    templateTypeId: {
      immediate: true,
      handler(value) {
        if (typeof value != undefined && this.reception) {
          const template = this.reception.visitor_template.find(
            template => template.visitor_template_type_id == value
          );

          if (template) {
            this.selectedTemplateIndex = this.reception.visitor_template.findIndex(
              template => template.visitor_template_type_id == value
            );
            this.setVisitorTemplate(template);
          }
        } else {
          this.$router.push({
            name: "r_edit-reception",
            params: this.$router.params
          });
        }
      }
    }
  }
};
</script>
